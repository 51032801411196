<template>
<div class="chinese_font">
    <!-- 轮播图 -->
    <el-carousel height="250px" class="banner" :autoplay=false>
        <el-carousel-item >
            <img class="banner_img" src="../assets/image/examBanner3.jpg">
        </el-carousel-item>
        <el-carousel-item >
            <img class="banner_img" src="../assets/image/examBanner2.jpg">
        </el-carousel-item>
        <el-carousel-item >
            <img class="banner_img" src="../assets/image/examBanner1.jpg">
        </el-carousel-item>
        <el-carousel-item >
            <img class="banner_img" src="../assets/image/lucky.jpg">
        </el-carousel-item>
    </el-carousel>
    <!-- 表情 -->
    <div class="menhera-container motion-container" style="display: block;">
        <a class="emoji-item" @click="emoji('(⌒▽⌒)')" style="cursor:hand">(⌒▽⌒)</a>
        <a class="emoji-item" @click="emoji('（￣▽￣）')" style="cursor:hand">（￣▽￣）</a>
        <a class="emoji-item" @click="emoji('(=・ω・=)')" style="cursor:hand">(=・ω・=)</a>
        <a class="emoji-item" @click="emoji('(｀・ω・´)')" style="cursor:hand">(｀・ω・´)</a>
        <a class="emoji-item" @click="emoji('(〜￣△￣)〜')" style="cursor:hand">(〜￣△￣)〜</a>
        <a class="emoji-item" @click="emoji('(･∀･)')" style="cursor:hand">(･∀･)</a>
        <a class="emoji-item" @click="emoji('(°∀°)ﾉ')" style="cursor:hand">(°∀°)ﾉ</a>
        <a class="emoji-item" @click="emoji('(￣3￣)')" style="cursor:hand">(￣3￣)</a>
        <a class="emoji-item" @click="emoji('╮(￣▽￣)╭')" style="cursor:hand">╮(￣▽￣)╭</a>
        <a class="emoji-item" @click="emoji('←_←')" style="cursor:hand">←_←</a>
        <a class="emoji-item" @click="emoji('(;¬_¬)')" style="cursor:hand">(;¬_¬)</a>
        <a class="emoji-item" @click="emoji('ﾟДﾟ≡ﾟдﾟ)!?')" style="cursor:hand">(ﾟДﾟ≡ﾟдﾟ)!?</a>
        <a class="emoji-item" @click="emoji('Σ(￣□￣||)')" style="cursor:hand">Σ(￣□￣||)</a>
        <a class="emoji-item" @click="emoji('（/TДT)/')" style="cursor:hand">（/TДT)/</a>
        <a class="emoji-item" @click="emoji('(｡･ω･｡)')" style="cursor:hand">(｡･ω･｡)</a>
        <a class="emoji-item" @click="emoji('(●￣(ｴ)￣●)')" style="cursor:hand">(●￣(ｴ)￣●)</a>
        <a class="emoji-item" @click="emoji('ε=ε=(ノ≧∇≦)ノ')" style="cursor:hand">ε=ε=(ノ≧∇≦)ノ</a>
        <a class="emoji-item" @click="emoji('(’･_･‘)')" style="cursor:hand">(’･_･‘)</a>
        <a class="emoji-item" @click="emoji('(-_-#)')" style="cursor:hand">(-_-#)</a>
        <a class="emoji-item" @click="emoji('（￣へ￣）')" style="cursor:hand">（￣へ￣）</a>
        <a class="emoji-item" @click="emoji('（#-_-)┯━┯')" style="cursor:hand">（#-_-)┯━┯</a>
        <a class="emoji-item" @click="emoji('(╯°口°)╯(┴—┴')" style="cursor:hand">(╯°口°)╯(┴—┴</a>
        <a class="emoji-item" @click="emoji('( ♥д♥)')" style="cursor:hand">( ♥д♥)</a>
        <a class="emoji-item" @click="emoji('Σ&gt;―(〃°ω°〃)♡→')" style="cursor:hand">Σ&gt;―(〃°ω°〃)♡→</a>
        <a class="emoji-item" @click="emoji('(╬ﾟдﾟ)▄︻┻┳═一')" style="cursor:hand">(╬ﾟдﾟ)▄︻┻┳═一</a>
    </div>
    <!-- 输入框 -->
    <el-input class="input"
        type="textarea"
        maxlength="100"
        show-word-limit
        :autosize="{ minRows: 2, maxRows: 3}"
        placeholder="每次留言需50积分,待班级管理员审核后发布,若出现不当言论,永久封号。"
        v-model="message_input">
    </el-input>
    <div>
        <el-button
        style="margin: 0 20vw 10px 20vw;" type="primary" plain 
        @click="submit">提交</el-button>
    </div>
    <!-- 留言板 -->
    <div class="card_section">
        <el-card class="box-card" v-for="message in message_list" :key="message.id">
            <div slot="header" class="clearfix">
                <!-- <img class="small_img" :src="require('@/assets/touxiang/'+message.avatar)"> -->
                <div class="flex_row" style="justify-content: space-between;">
                    <div class="flex_row" style="align-items: flex-end;">
                        <img style="border-radius: 8px;width: 45px;height: 45px;" :src="message.avatar">
                        <span>{{message.username}} </span>
                        <span class="date" v-if="message.class_name!='教师'">({{message.class_name}})</span>
                        <span style="color: rgb(220, 155, 33);"> LV{{message.ex}} </span>
                    </div>
                    <div class="flex_row">
                        <span class="date"> {{message.time}} </span>
                        <!-- 删除（操作权限2）|| $store.state.user_id == message.user_id -->
                        <i 
                            @click="del(message.id)" 
                            v-if="$store.state.admin>=2"
                            style="float: right;padding: 3px 0;font-size: 20px;" 
                            class="el-icon-delete">
                        </i>
                    </div>
                </div>

            </div>
            <div class="text item">
                <!-- 留言内容（已经审核通过，或者管理员权限>1） -->
                <div v-if="message.audit==1">
                    <div>{{message.message}}</div>
                    <div class="flex_row" style="justify-content: flex-end;">
                        <el-button style="margin-right: 5px;"
                         size="mini" type="info" disabled>已审核</el-button>
                        <div>审核员：{{message.auditor}}</div>
                    </div>
                </div>
                <div v-else>
                    审核状态：{{message.audit==0?'未审核':'已通过'}}
                </div>
                <!-- 审核(只有管理员权限>1可见) -->
                <div v-if="$store.state.admin>=1 && message.audit==0"
                    style="margin-top: 5vh;justify-content: space-between;"
                    class="flex_row"
                    >
                    <div>{{message.message}}</div>
                    <el-button v-if="message.audit==0"
                    @click="audit(message.id)"
                    type="primary" size="mini">审核</el-button>
                </div>
            </div>
        </el-card>
        <!-- 分页 -->
        <div class="pagination">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[25, 50, 75, 100]"
            :page-size="25"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
    <Rain></Rain>
</div>
</template>

<script>
import axios from 'axios'
import Rain from '../components/Rain'
export default {
    name:"MyMessage",
    components: {Rain},
    data(){
        return {
            message_input:'',     // 采集用户输入留言
            currentPage: 1,       // 当前的页码
            page_size: 50,        // 每页显示条数
            total:1,              // 列表总长度
            message_list:[]
        }
    },
    methods:{
        // 审核
        audit(id){
            let params = {
                message_id: id,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Message_mod.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    if(response.data.error == 1){
                        this.$message({
                            message: response.data.msg,
                            type: 'warning'
                        })
                    }else{
                        // 数据库已经修改成功
                        this.$message({
                            message: response.data.msg,
                            type: 'success',
                            duration: 1500
                        })
                        this.getMessageList()
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 添加表情
        emoji(content){
            this.message_input += content
        },
        // 提交留言
        submit(){
            // 发送数据
            let params = {
                message: this.message_input,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Message_add.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    if(response.data.error == 1){
                        this.$message({
                            message: response.data.msg,
                            type: 'warning'
                        })
                    }else{
                        this.$message({
                            message: response.data.msg,
                            type: 'success',
                            duration: 1500
                        })
                        this.getMessageList()
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 分页获取message表中数据
        getMessageList(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Message_get.php',
                params:params
            }).then(
                response => {
                    this.message_list = response.data.data
                    this.total = parseInt(response.data.total)
                    // console.log(this.message_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 删除留言
        del(id){
            // 发送请求
            let params = {
                dataSheet: 'message',
                id: id,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Delete.php',
                params:params
            }).then(
                response => {
                this.getMessageList()
                // 删除成功提示
                this.$message({
                    type: 'success',
                    message: response.data.msg
                });
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getMessageList()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getMessageList()
        },
    },
    mounted(){
        this.getMessageList()
    }
}
</script>

<style scoped>
.pagination{
    margin-top:2vh;
}
.card_section{
    width: 60vw;
    margin-left: 20vw;
}
.input{
    margin-bottom: 20px;
    font-size: 20px;
    width: 60vw;
    margin-left: 20vw;
    opacity: 0.6;
}
.banner{
    width: 60vw;
    margin-left: 20vw;
    margin-top: 15vh;
}
.banner_img{
    width: 100%;
    height: 100%;
}

#chat_banner{
    width: 700px;
    margin-bottom: 30px;
}

#chat_input{
    font-size: 24px;
}

/* ---表情--- */
.menhera-container .emoji-item {
    padding: 3px 5px;
    margin: 3px 3px;
    display: inline-block;
    border-radius: 4px;
    color: #505050
}

.menhera-container .emoji-item:hover {
    background-color: rgba(245, 245, 245, .8)
}

.motion-container {
    width: 60vw;
    margin-left: 20vw;
    margin-right: 20vw;
    height: 110px;
    overflow: auto;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #a0a0a0;
}
/* ---END--- */
.date{
    color: #B9B9B9;
    font-size: small;
    margin-right: 10px;
}


</style>