<template>
<div>
    <!-- 标题 -->
    <div style="width: 100vw;height: 5vh;margin-top: 10vh;" class="flex_row">
        <h3>通义万问</h3>
    </div>
    <!-- 回答 -->
    <div 
        class="section" 
        v-loading="loading"
        element-loading-text="AI模型计算较慢，等待时间较长"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <pre style="white-space: pre-wrap;width: 100%;">{{ content }}</pre>
    </div>
    <div class="flex_row" style="justify-content: space-evenly; width: 40vw;margin-left: 30vw;margin-top: 20px;background-color: white;border-radius: 12px;padding: 30px;">
        <el-input
            @keydown.enter.native.prevent="post_AI"
            style="width: 80%;"
            type="textarea"
            :rows="3"
            placeholder="因为我的个人电脑算力有限，承受不住大量请求，故暂定20积分使用一次"
            v-model="user_input">
        </el-input>
        <img @click="post_AI()" style="width: 50px;" src="../assets/image/commit.png" alt="">
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:'MyAI',
    data(){
        return{
            user_input:'',
            content:'我是通义万问，有什么可以帮到你的',
            loading:false
        }
    },
    methods:{
        post_AI(e){
            // if(this.$store.state.admin<2){
            //     this.$message({
            //         type:'warning',
            //         message:'无操作权限',
            //         duration:1000
            //     })
            //     return false
            // }
            // e.preventDefault() // 阻止默认的换行事件
            this.$MessageBox.confirm('每次提问消耗20积分，是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.loading = true
                    let params = {
                        user_input:this.user_input
                    }
                    axios({
                        method:'get',
                        url:'https://xiaolily.cn/AI_my.php',
                        params:params,
                    }).then(
                        response => {
                            console.log(response)
                            if(response.data.error == 1){
                                this.$message.error(response.data.msg);
                            }else{
                                // this.content = response.data.response
                                this.content = response.data.message.content
                            }
                            this.loading = false
                        },
                        error => {
                            this.$message.error('请求失败了');
                            console.log('请求失败了',error.message)
                            this.loading = false
                        }
                    )
                }) 
        },
    },
    mounted(){}
}
</script>

<style scoped>
.section{
    width: 40vw;
    margin-left: 30vw;
    margin-top: 20px;
    background-color: white;
    min-height: 40vh;
    border-radius: 12px;
    padding: 30px;
}
</style>